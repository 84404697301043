/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"
import blogListStyles from "../templates/blog-list.styles"

export const newsletterQuery = graphql`
  query newsletterListQuery {
    allStrapiNewsletters(sort: { order: DESC, fields: PublishDate }) {
      edges {
        node {
          id
          meta_description
          PublishDate(formatString: "MMMM DD, YYYY")
          Title
          Slug
          prefix
        }
      }
    }
  }
`

class NewsletterPage extends React.Component {
  render() {
    const { data } = this.props

    const posts = data.allStrapiNewsletters.edges
      .filter(edge => !!edge.node.PublishDate)
      .map(edge => {
        const props = {
          prefix: edge.node.prefix,
          slug: edge.node.Slug,
          date: edge.node.PublishDate,
          title: edge.node.Title,
        }
        return <PostCard key={edge.node.id} data={props} />
      })
    return (
      <Layout>
        <SEO
          title={"Newsletter • Stackrole"}
          description={
            "Get latest news on Jamstack, How-to guides and best Jamstack templates in your inbox"
          }
        />
        <div className="blog-posts" sx={blogListStyles.posts}>
          <div sx={blogListStyles.container}>
            <div>
              <h1 sx={blogListStyles.heading}>Newsletter</h1>
              <p sx={blogListStyles.paragraph}>
                Get latest news on Jamstack, How-to guides and best Jamstack
                templates in your inbox.
              </p>
            </div>
            <div className="post-grids">{posts}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NewsletterPage
